<template>
  <div class="top-box menu-box">
    <span class="inline"></span>
    <a-breadcrumb separator=">" style="font-size:18px">
      <a-breadcrumb-item><router-link :to="{name:'ProjectManger'}">项目管理</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link :to="{name:'projectDetail', params:{id: id}}">项目详情</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{team_name}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
  <!-- {{'isShowTickets_' + isShowTickets}} / {{'isShowTicketsBtn_' + isShowTicketsBtn}}/{{'isShowExamine_'+isShowExamine}}/{{'isShowTeamBtn_'+isShowTeamBtn}} -->
  <div class="box-shadow content">
    <div class="itemBox flex" v-if="isShowTickets">
      <div class="label">操作票：</div>
      <div class="info">
        <div class="flex-align">
          <a-select v-model:value="ticket_id" style="width:200px;" placeholder="请选择操作票">
            <a-select-option v-for="item in ticketsList" :value="item.id" :key="item.id">{{item.ticket}}</a-select-option>
          </a-select>
          <a-button type="primary" class="btn" @click="handleExamineProject" v-if="isShowTicketsBtn">提交审核</a-button>
        </div>
        <a-table class="tables mt-20" :dataSource="check_item" :columns="ticketColumns" rowKey="parent_id">
          <template #process="{record}">
            <span class="process" @click="goCard(record.process_id)">{{record.process}}</span>
          </template>
          <template #action1="{record}">
            <!-- 是否为班长提交操作票子项 身份为班长 组员未勾 选时可操作 -->
            <a-checkbox
              :disabled="!(ROLE_STATE[accountInfo.role_id] === '班长' && parseInt(team_id) === accountInfo.team_id) || (record.teamtor_check && record.crew_check)"
              v-model:checked="record.teamtor_check"
              style="margin-right:20px;"
              @change="changeChecked(record,$event)">
              </a-checkbox>
              <span class="s_icon" v-if="!record.teamtor_check && record.crew_check">!</span>
          </template>
          <template #action2="{record}">
            <a-checkbox
              :disabled="!(ROLE_STATE[accountInfo.role_id] === '班长' && parseInt(team_id) === accountInfo.team_id) || record.crew_check"
              v-model:checked="record.crew_check"
              @change="changeChecked(record,$event)"
              ></a-checkbox>
          </template>
          <!-- <template #action3="{record}">
            <a-button type="link" @click="openFiles(record)">附件</a-button>
          </template> -->
        </a-table>
        <div class="btnFlex mt-20">
          <a-button type="primary" @click="openProjectFiles(0)" style="margin-right:20px">项目附件</a-button>
          <a-button type="primary" @click="fetchRunProject" v-if="isShowTeamBtn">提交审核</a-button>
        </div>
      </div>
    </div>
    <div class="itemBox flex" v-if="isShowExamine">
      <div class="label">审&nbsp;&nbsp;&nbsp;&nbsp;核：</div>
      <div class="info">
        <a-button type="primary" @click="checkProjectHttp(true)">通过</a-button>
        <a-button style="margin-left:20px" @click="checkProjectHttp(false)">不通过</a-button>
      </div>
    </div>
    <div class="itemBox flex">
      <div class="label">日&nbsp;&nbsp;&nbsp;&nbsp;志：</div>
      <div class="info">
        <a-table class="tables" :dataSource="logs" :columns="columns" rowKey="add_time" />
      </div>
    </div>
  </div>

  <!-- 填写不通过的原因 -->
  <a-modal v-model:visible="visible" title="填写不通过原因" okText="确定" cancelText="取消" @ok="handleOk">
      <a-input
        v-model:value="reason"
        placeholder="请输入不通过的原因"
        :maxlength="100"
        autocomplete="off"></a-input>
  </a-modal>

  <!-- 附件 -->
  <filesDialog
    ref="filesDialogRef"
    v-model:visible="visibleFiles"
    :project_id="project_id"
    :team_id="team_id"
    :ticket_id="ticket_id"
    :ticket_item_id="ticket_item_id"
    :isAdd="isShowTeamBtn"></filesDialog>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getProjecRun, examineProject, checkProject, projectTeamLog, itemSubmit, projectFiels } from '@/api/index'
import { message } from 'ant-design-vue'
import { TEAM_STATE, ROLE_STATE, MAJOR_STATE } from '@/hooks/config.ts'
import filesDialog from '@/views/projectManger/components/filesDialog.vue'
import { useRouter } from 'vue-router'
const ticketColumns = [
  {
    title: '项',
    dataIndex: 'parent_id',
    key: 'parent_id'
  },
  {
    title: '操作票内容(项)',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '工序卡',
    dataIndex: 'process',
    key: 'process',
    slots: { customRender: 'process' }
  },
  {
    title: '班长',
    key: 'action1',
    slots: { customRender: 'action1' }
  },
  {
    title: '组员',
    key: 'action2',
    slots: { customRender: 'action2' },
    align: 'right'
  },
  {
    title: '',
    key: 'action3',
    slots: { customRender: 'action3' },
    align: 'right'
  }
]
const columns = [
  {
    title: '时间',
    dataIndex: 'add_time',
    key: 'add_time'
  },
  {
    title: '操作人',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: '操作内容',
    dataIndex: 'remark',
    key: 'remark'
  }
]
export default defineComponent({
  name: 'teamDetail',
  props: ['project_id', 'team_id'], // 项目id
  components: { filesDialog },
  setup (props) {
    const router = useRouter()
    const filesDialogRef = ref()
    const id = computed(() => props.project_id)
    const visible = ref(false)
    const reason = ref('')
    const activeKey = ref(['1'])
    const ticket_id = ref()
    const ticket_item_id = ref()
    const accountInfo = ref({})
    const runObj = reactive({
      ticketsList: [],
      check_item: [],
      files: [],
      logs: [],
      visibleFiles: false, // 附件弹框
      team_state: null,
      item_check: [], // 勾选子项集合
      isShowTickets: false, // 是否显示操作票 身份为班长 或 状态不为0和1
      isShowTicketsBtn: false, // 是否显示操作票审核按钮 身份为该组班长 状态为待审核操作票之前(小于2)
      isShowTeamBtn: false, // 是否显示项目提交审核按钮 身份为该组班长 且(操作票子项为0或子项全部审核完成) 且 状态为4(运行作业中) 或 -5(作业验收未通过)
      isShowExamine: false // 是否显示审核按钮 身份为值班负责人且状态为2(待审核操作票) 或 为5待验收作业
    })
    const isAllCheck = computed(() => {
      if (runObj.check_item) {
        return runObj.check_item.filter((item) => {
          return item.check
        }).length === runObj.check_item.length
      } else {
        return true
      }
    })
    const getProjecRuns = () => {
      getProjecRun({
        project_id: props.project_id,
        team_id: props.team_id
      }).then(res => {
        runObj.ticketsList = res.data.tickets ? res.data.tickets : [{
          id: res.data.ticket_id,
          ticket: res.data.ticket
        }]
        ticket_id.value = res.data.ticket_id === 0 ? null : res.data.ticket_id
        runObj.check_item = res.data.check_item ? res.data.check_item : []
        runObj.files = res.data.files
        runObj.team_state = res.data.team_state
        // 是否显示操作票 (身份为班长 或 状态不为0和1(待传操作票))
        console.log(accountInfo.value)
        runObj.isShowTickets = ROLE_STATE[accountInfo.value.role_id] === '班长' || runObj.team_state === 0 || TEAM_STATE[runObj.team_state] !== '待传操作票'
        // 是否显示操作票审核按钮 状态为待审核操作票之前(小于2) 身份为该组班长
        runObj.isShowTicketsBtn = runObj.team_state < 2 && ROLE_STATE[accountInfo.value.role_id] === '班长' && parseInt(props.team_id) === accountInfo.value.team_id
        // 是否显示审核按钮 身份为值班负责人且状态为2(待审核操作票) 或 为5待验收作业
        runObj.isShowExamine = ROLE_STATE[accountInfo.value.role_id] === '负责人' && (TEAM_STATE[runObj.team_state] === '待审核操作票' || TEAM_STATE[runObj.team_state] === '待验收作业')
        // 是否显示项目提交审核按钮 身份为该组班长 且(操作票子项为0或子项全部审核完成) 且 状态为4(运行作业中) 或 -5(作业验收未通过)
        runObj.isShowTeamBtn = ROLE_STATE[accountInfo.value.role_id] === '班长' && parseInt(props.team_id) === accountInfo.value.team_id && (TEAM_STATE[runObj.team_state] === '运行作业中' || TEAM_STATE[runObj.team_state] === '作业验收未通过')
      })
    }
    // 获取小组运行日志
    const getProjectTeamLog = () => {
      projectTeamLog({
        project_id: props.project_id,
        team_id: props.team_id
      }).then(res => {
        runObj.logs = res.data
      })
    }
    // 提交审核请求
    const examineProjectHttp = (_obj) => {
      examineProject(Object.assign({
        project_id: parseInt(props.project_id),
        team_id: parseInt(props.team_id)
      }, _obj)).then(res => {
        console.log(res)
        message.success('提交审核成功')
        getProjecRuns()
        getProjectTeamLog()
      })
    }
    // 提交操作票审核
    const fetchExamineProject = () => {
      examineProjectHttp({ ticket_id: ticket_id.value })
    }
    // 提交操作票子项审核
    // const fetchItemProject = () => {
    //   examineProjectHttp({ ticket_item_id: ticket_item_id.value })
    // }
    const getCheckItem = () => {
      return runObj.check_item.filter(item => {
        return item.teamtor_check || item.crew_check
      }).map(item => {
        return {
          ticket_item_id: item.ticket_item_id,
          teamtor_check: item.teamtor_check,
          crew_check: item.crew_check,
          modify_time: item.modify_time
        }
      })
    }
    // 提交运行审核
    const fetchRunProject = () => {
      // examineProjectHttp({})
      console.log(getCheckItem(), '2222')
      itemSubmit({
        project_id: parseInt(props.project_id),
        team_id: parseInt(props.team_id),
        ticket_id: ticket_id.value,
        item_check: getCheckItem()
      }).then(res => {
        message.success('提交成功')
        getProjecRuns()
        getProjectTeamLog()
      })
    }
    const handleExamineProject = () => {
      if (ticket_id.value) {
        fetchExamineProject()
      } else {
        message.error('请先选择操作票')
      }
    }
    // 获取时间
    const getTime = () => {
      const date = new Date()
      const yy = date.getFullYear()
      const mm = date.getMonth() + 1
      const dd = date.getDate()
      const hh = date.getHours()
      const mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      const dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
      console.log(dateTime)
      return dateTime
    }
    // 子项提交审核
    const changeChecked = (obj, value) => {
      console.log(obj, '11111')
      obj.modify_time = getTime()
    }
    // 负责人审核
    const fetchCheckProjectHttp = (bool) => {
      checkProject({
        project_id: parseInt(props.project_id),
        team_id: parseInt(props.team_id),
        pass: bool,
        reason: reason.value
      }).then(() => {
        message.success(bool ? '已通过审核' : '已拒绝审核')
        getProjecRuns()
        getProjectTeamLog()
      })
    }
    const checkProjectHttp = (bool) => {
      if (bool) {
        fetchCheckProjectHttp(bool)
      } else {
        visible.value = true
      }
    }
    const handleOk = () => {
      // console.log(e);
      if (reason.value) {
        fetchCheckProjectHttp(false)
        visible.value = false
      } else {
        message.error('请先输入不通过的原因')
      }
    }
    // 打开附件弹框
    const openFiles = (obj) => {
      runObj.visibleFiles = true
      ticket_item_id.value = obj.ticket_item_id
      filesDialogRef.value.projectFielsHttp()
    }
    const openProjectFiles = (value) => {
      runObj.visibleFiles = true
      ticket_item_id.value = value
      filesDialogRef.value.projectFielsHttp()
    }
    const goCard = (id) => {
      router.push({ name: 'EditProcess', params: { id } })
    }
    onMounted(() => {
      if (sessionStorage.getItem('accountInfo')) {
        accountInfo.value = JSON.parse(sessionStorage.getItem('accountInfo'))
      }
      getProjecRuns()
      getProjectTeamLog()
    })
    return {
      ROLE_STATE,
      TEAM_STATE,
      team_name: sessionStorage.getItem('team_name'),
      id,
      activeKey,
      ticket_id,
      ticket_item_id,
      ...toRefs(runObj),
      handleExamineProject,
      columns,
      ticketColumns,
      changeChecked,
      isAllCheck,
      fetchRunProject,
      checkProjectHttp,
      accountInfo,
      visible,
      handleOk,
      reason,
      openFiles,
      filesDialogRef,
      openProjectFiles,
      goCard
    }
  }
})
</script>
<style lang="scss" scoped>
.menu-box{
  width: auto;
  max-width: 500px;
}
.content{
  padding: 30px;
}
.flex-align{
  display: flex;
  align-items: center;
}
.itemBox{
  width:100%;
  margin-bottom: 20px;
  // background: red;
  .label{
    flex-shrink: 0;
    font-size: 16px;
    // margin-right: 30px;
  }
  .info{
    flex:1;
    margin-top:-5px;
    margin-left:20px;
    .btn{
      margin-left:20px;
    }
  }
  .tables{
    width: 100%;
  }
  .mt-20{
     margin-top:20px;
  }
}
.s_icon{
  color:red;
  font-size: 20px;
  // margin-left:10px;
}
.btnFlex{
  display: flex;
  align-items: center;
}
.process{
  cursor: pointer;
  &:hover{
    color:#1890ff;
  }
}
</style>
