<template>
  <a-modal
    width="70%"
    :visible="visible"
    title="附件"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handelOK">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="图片">
        <a-row :gutter="20" v-if="resouseImgList.length>0">
          <a-col :span="4" v-for="(element, index) in resouseImgList" :key="index">
            <div class="img-box">
              <a-image
              v-if="element.url"
              :src="element.url"
              />
              <a-image
              :src="OSSURL + '/' + element.file_path"
              v-else
              />
              <DeleteOutlined v-if="isAdd" class="delete-icon" @click.prevent="deleteFile(resouseImgList, index)"/>
              <div :title="element.name ? element.name : element.file_name" class="text-name">{{element.name ? element.name : element.file_name}}</div>
            </div>
          </a-col>
        </a-row>
        <a-empty v-else :image="simpleImage" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="视频">
        <a-row :gutter="20" v-if="resouseVideoList.length>0">
          <a-col :span="4" class="mb-20" v-for="(element, index) in resouseVideoList" :key="index">
            <div class="re-item img-box box-shadow">
              <div class="file-icon">
                <a-image :preview="false" :src="OSS_ICON[element.type ? element.type : element.file_type].url"></a-image>
              </div>
              <div class="text-box" @click="hanldePlay(element)"><span>{{element.name ? element.name : element.file_name}}</span></div>
              <DeleteOutlined v-if="isAdd" class="delete-icon" @click.prevent="deleteFile(resouseVideoList, index)"/>
            </div>
          </a-col>
        </a-row>
        <a-empty v-else :image="simpleImage" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="音频">
        <a-row :gutter="20" v-if="resouseAudioList.length>0">
          <a-col :span="4" v-for="(element, index) in resouseAudioList" :key="index">
            <div class="re-item img-box box-shadow">
              <div class="file-icon">
                <a-image :preview="false" :src="OSS_ICON[element.type ? element.type : element.file_type].url"></a-image>
              </div>
              <div class="text-box" @click="hanldePlay(element)"><span>{{element.name ? element.name : element.file_name}}</span></div>
              <DeleteOutlined v-if="isAdd" class="delete-icon" @click.prevent="deleteFile(resouseAudioList, index)"/>
            </div>
          </a-col>
        </a-row>
        <a-empty v-else :image="simpleImage" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="word">
        <a-row :gutter="20" v-if="resouseFileList.length>0">
          <a-col :span="4" v-for="(element, index) in resouseFileList" :key="index">
            <div class="re-item img-box box-shadow">
              <div class="file-icon">
                <a-image :preview="false" :src="OSS_ICON[element.type ? element.type : element.file_type].url"></a-image>
              </div>
              <div class="text-box" @click="hanldeReview(element)"><span>{{element.name ? element.name : element.file_name}}</span></div>
              <DeleteOutlined v-if="isAdd" class="delete-icon" @click.prevent="deleteFile(resouseFileList, index)"/>
            </div>
          </a-col>
        </a-row>
        <a-empty v-else :image="simpleImage" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="文字备注">
        <a-textarea v-model:value="textInput[0].remarks" placeholder="请输入文字备注" :rows="4" />
      </a-tab-pane>
      <template #tabBarExtraContent v-if="isAdd">
        <div class="flex" v-if="activeKey == 1">
          <upload-ab ref="uploadReImg" @changeFile="changeResouseImg" id="resouseImg" :list="resouseImgList" :upObj="[SEND_TYPE[1]]" :isAuto="true">上传图片</upload-ab>
        </div>
        <div class="flex" v-if="activeKey == 2">
          <upload-ab ref="uploadReVideo" @changeFile="changeResouseVideo" id="resouseVideo" :list="resouseVideoList" :upObj="[SEND_TYPE[3]]" :isAuto="true">上传视频</upload-ab>
        </div>
        <div class="flex" v-if="activeKey == 3">
          <upload-ab ref="uploadReAudio" @changeFile="changeResouseAudio" id="resouseAudio" :list="resouseAudioList" :upObj="[SEND_TYPE[2]]" :isAuto="true">上传音频</upload-ab>
        </div>
        <div class="flex" v-if="activeKey == 4">
          <upload-ab ref="uploadReFile" @changeFile="changeResouseFile" id="resouseFile" :list="resouseFileList" :upObj="[SEND_TYPE[4]]" :isAuto="true">上传word</upload-ab>
        </div>
      </template>
    </a-tabs>
  </a-modal>

  <!-- 视频预览 -->
  <videoDialog v-if="visibleVideo" v-model:href="playUrl" v-model:visible="visibleVideo"></videoDialog>
  <!-- 音频预览 -->
  <radioDialog v-if="visibleRadio" v-model:href="playUrl" v-model:visible="visibleRadio"></radioDialog>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { PROJECT_TYPE_new, OSSURL, OSS_ICON } from '@/hooks/ali-oss'
import uploadAb from '@/views/encyclopediasManger/components/upload-ab.vue'
import { message, Empty } from 'ant-design-vue'
import { updateFiles, projectFiels, deleteFiles } from '@/api/index'
import { DeleteOutlined } from '@ant-design/icons-vue'
import videoDialog from '@/components/videoDialog.vue'
import radioDialog from '@/components/radioDialog.vue'
export default defineComponent({
  props: {
    visible: {},
    project_id: {},
    team_id: {},
    ticket_id: {},
    ticket_item_id: {},
    isAdd: {}
  },
  components: {
    uploadAb,
    DeleteOutlined,
    videoDialog,
    radioDialog
  },
  setup (props, ctx) {
    const visibleVideo = ref(false)
    const visibleRadio = ref(false)
    const playUrl = ref()
    const deleteIds = ref([]) // 要删除附件的集合
    const handleCancel = () => {
      ctx.emit('update:visible', false)
    }
    const textInput = ref([{
      type: '',
      remarks: ''
    }])
    const resouseImgList = ref([])
    const changeResouseImg = (lists) => {
      console.log(lists, '图片')
      resouseImgList.value = lists
    }
    const resouseVideoList = ref([])
    const changeResouseVideo = (lists) => {
      console.log(lists, 'resouseVideo')
      resouseVideoList.value = lists
    }
    const resouseAudioList = ref([])
    const changeResouseAudio = (lists) => {
      console.log(lists, 'resouseVideo')
      resouseAudioList.value = lists
    }
    const resouseFileList = ref([])
    const changeResouseFile = (lists) => {
      console.log(lists, 'resouseFilei')
      resouseFileList.value = lists
    }
    // 获取附件
    const projectFielsHttp = () => {
      projectFiels({
        project_id: parseInt(props.project_id),
        team_id: parseInt(props.team_id),
        ticket_id: parseInt(props.ticket_id)
      }).then(res => {
        // const _arr = res.data.filter(item => {
        //   return item.ticket_item_id === props.ticket_item_id
        // })
        console.log(res.data)
        resouseImgList.value = res.data.filter((ele) => {
          return ele.type === 'p1'
        })
        resouseVideoList.value = res.data.filter((ele) => {
          return ele.type === 'm4'
        })
        resouseAudioList.value = res.data.filter((ele) => {
          return ele.type === 'm3'
        })
        resouseFileList.value = res.data.filter((ele) => {
          return ele.type === 'w1'
        })
        textInput.value[0].remarks = res.data.filter((ele) => {
          return ele.type === '' && ele.remarks
        })[0].remarks
      })
    }
    // 重置list值
    const resetList = (lists) => {
      return lists.map((ele) => {
        if (ele.file_name) {
          return {
            type: ele.file_type ? ele.file_type : ele.type,
            file_name: ele.file_name,
            file_path: ele.file_path
          }
        } else if (ele.remarks) {
          return {
            type: ele.file_type ? ele.file_type : ele.type,
            remarks: ele.remarks
          }
        }
      })
    }
    // 请求
    const updateFilesHttp = (list) => {
      console.log(list, 'dd')
      updateFiles({
        project_id: parseInt(props.project_id),
        team_id: parseInt(props.team_id),
        ticket_id: parseInt(props.ticket_id),
        ticket_item_id: parseInt(props.ticket_item_id),
        files: list
      }).then(res => {
        message.success('附件提交成功')
        handleCancel()
        // projectFielsHttp()
      })
    }
    // 删除附件请求
    const deleteFilesHttp = () => {
      deleteFiles({
        team_id: parseInt(props.team_id),
        file_ids: deleteIds.value
      }).then(res => {
        message.success('附件删除成功')
        handleCancel()
      })
    }
    const handelOK = () => {
      let list = resouseImgList.value.concat(resouseVideoList.value, resouseFileList.value, resouseAudioList.value)
      if (textInput.value[0].remarks) {
        list = list.concat(textInput.value)
      }
      // console.log(list)
      // const oldList = list.filter((item) => {
      //   return item.id !== undefined
      // })
      const newsList = list.filter((item) => {
        return item.id === undefined
      })
      if (props.isAdd) {
        console.log(deleteIds.value)
        if (deleteIds.value.length > 0) {
          deleteFilesHttp()
        } else {
          updateFilesHttp(resetList(newsList))
        }
      } else {
        handleCancel()
      }
    }
    // 播放音频或者视频
    const hanldePlay = (record) => {
      if (record.type === 'm3') {
        visibleRadio.value = true
      } else {
        visibleVideo.value = true
      }
      playUrl.value = OSSURL + '/' + record.file_path
    }
    // 预览文档
    const hanldeReview = (record) => {
      if (record.type === 'w4') {
        // pdf跳转地址
        console.log('等待环境搭建')
        alert('等待环境搭建,先不管')
      } else {
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${OSSURL}/${record.file_path}`)
      }
    }
    // 删除文件
    const deleteFile = (list, index) => {
      if (list[index].id) {
        deleteIds.value.push(list[index].id)
      }
      list.splice(index, 1)
    }
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      SEND_TYPE: PROJECT_TYPE_new,
      OSS_ICON,
      handleCancel,
      activeKey: ref('1'),
      resouseImgList,
      changeResouseImg,
      resouseVideoList,
      changeResouseVideo,
      resouseAudioList,
      changeResouseAudio,
      resouseFileList,
      changeResouseFile,
      textInput,
      handelOK,
      projectFielsHttp,
      OSSURL,
      visibleVideo,
      visibleRadio,
      playUrl,
      hanldePlay,
      hanldeReview,
      deleteFile
    }
  }
})
</script>
<style lang="scss" scoped>
.img-box{
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  width:100%;
  height:100%;
  ::v-deep .ant-image {
    width:100%;
    height:100px;
  }
  ::v-deep img{
    object-fit: cover;
    height: 100%;
  }
  .text-name{
    text-align: center;
    width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-image-img{
    width:100%;
    height:100%;
  }
  .delete-icon{
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    background: rgba($color: #000000, $alpha: .5);
    color: #fff;
    z-index: 1;
  }
  &:hover{
    .delete-icon{
      display: block;
    }
  }
}
.re-item{
  width: 100%;
  height:auto;
  padding: 10px;
  display: flex;
  align-items: center;
  .file-icon{
    margin-right: 10px;
  }
  ::v-deep .ant-image {
    width:40px;
    height:100%;
    flex-shrink: 0;
  }
  ::v-deep img{
    object-fit: cover;
    height: 100%;
  }
  .text-box {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // background: red;
    &:hover{
      color: #206CFE;
    }
  }
}

.mb-20{
  margin-bottom: 20px;
}
::v-deep .ant-row{
  max-height: 600px;
  min-height: 200px;
  overflow: auto;
}
</style>
